import { Box, Container, Typography, Grid, Card, Button, TextField, CardContent, Icon } from "@mui/material";
import React from "react";
import { commonStyle } from './common_style';
import imagePath from './../assets/images/Frame.png';
import about_1 from './../assets/images/about-1.png';
import { styled } from '@mui/material/styles';

const StyledImg = styled('img')({});
const AboutUs = () => {
    const classes = commonStyle();
    return (
        <Box>
            <Container>
                <Box py={5} textAlign='center'>
                    <img src={imagePath} height='80' />
                    <Typography variant="h1" color="primary" fontWeight='900' fontSize='48px' my={3}>About MEMSS</Typography>
                    <Typography variant="h4" color="#666666" lineHeight={2} fontSize='18px'>MEMSS, is owned by WT Data Tech.We are a new U.K. company founded in 2017. Our multi-vendor MEMSS software is a product of several decades of experience and hard work.</Typography>
                </Box>
                <Box py={10}>
                    <Grid container justifyContent={'center'} display={'flex'} alignItems={'center'} zIndex={2} position='relative' spacing={8} >
                        <Grid item xs={12} md={5}>
                            <StyledImg src={about_1} sx={{
                                maxWidth: '100%', maxHeight: '100%'
                            }} />
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <Typography variant="h4" color="primary" fontWeight='900' fontSize='48px' my={3}>Who We Are?</Typography>
                            <Typography variant="h4" color="#666666" lineHeight={2} fontSize='16px'>Established in 2017, we are WT Data Tech—the developers of MEMSS. We have created this software to provide the ultimate medical equipment and asset management and maintenance solution to anyone specialising in highly regulated industries in the UK or any part of the world.
                                The success of MEMSS can be gauged by our ever-increasing global and local client base. We have partnered with hundreds of clients worldwide and helped them integrate MEMSS for optimum medical equipment maintenance and management.</Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Grid container justifyContent={'center'} display={'flex'} spacing={5} pb={10}>
                    <Grid item xs={12} md={4}>
                        <Card className={classes.cst_block} color='primary' sx={{ height: '100%' }}>
                            <Button fullWidth color='primary' sx={{ fontWeight: '900', padding: '20px' }} >
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start', width: '100%' }}>
                                    <CardContent sx={{ p: '10px !important', display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                                        <Typography gutterBottom variant="h5" component="div" m='0px' fontSize={18} fontWeight='bold'>
                                            Our Mission
                                        </Typography>
                                        <Typography gutterBottom variant="h5" textTransform={'capitalize'} component="div" m='0px' fontSize={16} my={3} lineHeight={1.7} textAlign={'start'}>
                                            To provide next-generation and user-friendly equipment maintenance and management software solutions that help clients experience successful transformation and deliver best in service.
                                        </Typography>
                                    </CardContent>
                                </Box>
                            </Button>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card className={classes.cst_block} color='warning' sx={{ height: '100%' }}>
                            <Button fullWidth color='warning' sx={{ fontWeight: '900', padding: '20px' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start', width: '100%' }}>
                                    <CardContent sx={{ p: '10px !important', display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                                        <Typography gutterBottom variant="h5" component="div" m='0px' fontSize={18} fontWeight='bold'>
                                            Our Vision
                                        </Typography>
                                        <Typography gutterBottom variant="h5" textTransform={'capitalize'} component="div" m='0px' fontSize={16} my={3} lineHeight={1.7} textAlign={'start'}>
                                            To become the leading software company for providing innovative and world-class software solutions that are easy to deploy and leverage, thus empowering organisation’s to success while ensuring environmental sustainability
                                        </Typography>
                                    </CardContent>
                                </Box>
                            </Button>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card className={classes.cst_block} color='error' sx={{ height: '100%' }}>
                            <Button fullWidth color='error' sx={{ fontWeight: '900', padding: '20px' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start', width: '100%' }}>
                                    <CardContent sx={{ p: '10px !important', display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                                        <Typography gutterBottom variant="h5" component="div" m='0px' fontSize={18} fontWeight='bold'>
                                            Our Values
                                        </Typography>
                                        <Typography gutterBottom variant="h5" textTransform={'capitalize'} component="div" m='0px' fontSize={16} my={3} lineHeight={1.7} textAlign={'start'}>
                                            Our values guide us and include serving with integrity, fostering trust-based relationships, pursuing innovation and excellence, and promoting environmental safety and sustainability.
                                        </Typography>
                                    </CardContent>
                                </Box>
                            </Button>
                        </Card>
                    </Grid>
                </Grid>
                <Box py={10}>
                    <Grid container justifyContent={'center'} display={'flex'} alignItems={'center'} zIndex={2} position='relative' spacing={8} >

                        <Grid item xs={12} md={7}>
                            <Typography variant="h4" color="primary" fontWeight='900' fontSize='48px' my={3}>What Do We Do?</Typography>
                            <Typography variant="h4" color="#666666" lineHeight={2} fontSize='16px'>At WT Data Tech, we innovate. We strive to create and develop innovative maintenance management software and technological solutions that help clients achieve optimum equipment efficiencies and performance. Our MEMSS equipment maintenance management solution is a milestone in our effort to playing a pivotal role in assisting facilities and services providers to experience successful transformation in equipment maintenance.</Typography>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <StyledImg src={about_1} sx={{
                                maxWidth: '100%', maxHeight: '100%'
                            }} />
                        </Grid>
                    </Grid>
                </Box>
                <Box py={15} textAlign='center'>
                    <Typography variant="h1" color="primary" fontWeight='900' fontSize='48px' my={3}>Contact Us</Typography>
                    <Typography variant="h3" color="#666666" fontWeight='900' mx={{sm:'0px', md:'15'}} px={{sm:'0px', md:'15'}} fontSize='18px'>For more product information fill in the form to request a free no-obligation demonstration</Typography>
                    <Grid spacing={2} alignItems="center" justifyContent={'center'} display={'flex'} margin={'0px auto'}>
                        <Grid container item xs={12} md={9} textAlign={'start'} >
                            <Card className={classes.cst_block} color='primary' sx={{ height: '100%', marginY: '50px' }} >
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start', }} pb={5} pt={4}>
                                    <CardContent sx={{ p: '10px !important', display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                                        <Grid container spacing={2}>
                                            <Grid container item xs={12} md={5} textAlign={'start'} mb={2} >
                                                <Grid item textAlign={'start'} xs={12} md={'auto'}>
                                                    <Button sx={{ background: 'rgba(93, 95, 239, 0.2)', width: '50px', height: '50px', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '50px', mx:'15px' }}>
                                                        <Icon className="memss_c-address-1" sx={{}} color='primary'></Icon>
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={9} md={9} textAlign={'start'} >
                                                    <Typography variant='h1' color={'primary'} marginBottom={'15px'} fontWeight={'900'} fontSize={'20px'}>
                                                        Address
                                                    </Typography>
                                                    <Typography variant='body1' color={'primary'} fontSize={'14px'} fontWeight={'bold'}>
                                                    WTDATATECH LTD ,Suite 105-2 Fairbourne Drive, Atterbury, Milton Keynes, England, MK10 9RG
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={'auto'} md={2} textAlign={'start'}  mb={2}></Grid>
                                            <Grid container item xs={12} md={5} textAlign={'start'}   mb={2}>
                                            <Grid item textAlign={'start'} xs={12} md={'auto'}>
                                                    <Button sx={{ background: 'rgba(93, 95, 239, 0.2)', width: '50px', height: '50px', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '50px', mx:'15px' }}>
                                                        <Icon className="memss_c-Vector-5" sx={{}} color='primary'></Icon>
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={9} md={9} textAlign={'start'} >
                                                    <Typography variant='h1' color={'primary'} marginBottom={'15px'} fontWeight={'900'} fontSize={'20px'}>
                                                        Contact Us
                                                    </Typography>
                                                    <Typography variant='body1' color={'primary'} fontSize={'14px'} fontWeight={'bold'} marginBottom={'10px'}>
                                                        cs.memss.co.uk
                                                    </Typography>
                                                    <Typography variant='body1' color={'primary'} fontSize={'14px'} fontWeight={'bold'}>
                                                        (+44)1908 803 408
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Box>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Container >
        </Box >
    )
}

export default AboutUs;